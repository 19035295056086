<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        页面管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增页面</el-button>
      </div>
       <!-- 当前接口信息展示 -->
       <el-table :data="tableDataPage" style="width: 100%">
        <el-table-column
          prop="page_id"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="page_name"
          label="页面名称">
        </el-table-column>
        <el-table-column
          prop="page_addr"
          label="页面地址">
        </el-table-column>
        <el-table-column
          prop="project_id"
          label="所属项目">
        </el-table-column>
        <el-table-column
          prop="module_id"
          label="所属模块">
        </el-table-column>
        <el-table-column
          prop="description"
          label="页面描述">
        </el-table-column>
        <el-table-column
          prop="status"
          label="页面状态">
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          width="150">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 测试接口分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增编辑弹窗 -->
    <el-dialog
      :title="isAdd?'新增接口':'编辑接口'"
      :visible.sync="dialogVisiblePage"
      width="50%"
      @close="closeDialogHandle">
      <el-form :model="ruleFormPage" :rules="FormRulesPage" ref="ruleFormRefPage" label-width="120px" class="demo-ruleForm">
        <el-form-item v-if="!isAdd" label="页面ID">
          <el-input v-model="ruleFormPage.page_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="页面名称" prop="page_name">
          <el-input v-model="ruleFormPage.page_name"></el-input>
        </el-form-item>
        <el-form-item label="页面地址" prop="page_name">
          <el-input v-model="ruleFormPage.page_addr"></el-input>
        </el-form-item>
        <el-form-item label="所属项目/模块" prop="page_name">
          <el-cascader
            v-model="moduleCascaderValue"
            :options="moduleCascaderOptions"
            :props="{ expandTrigger: 'hover', label: 'name', value: 'id'}"
            @change="moduleHandleChange"
            style="width: 100%;">
          </el-cascader>
        </el-form-item>
        <el-form-item label="页面状态" prop="page_name">
          <el-switch v-model="ruleFormPage.status" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="页面描述" prop="description">
          <el-input type="textarea" v-model="ruleFormPage.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePage = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAddPage">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEditPage">编 辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryPageList, addPage, editPage, deletePage } from '@/services/uiPage.js'
import { queryCascader } from '@/services/uiCascader.js'
export default {
  name: 'UiPage',
  data () {
    return {
      isAdd: true,
      tableDataPage: [],
      pageInfo: {
        currentPage: 1,
        size: 10,
        total_count: null
      },
      dialogVisiblePage: false,
      ruleFormPage: {},
      FormRulesPage: {
        page_name: [
          { required: true, message: '请输环境名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入环境描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ]
      },
      moduleCascaderValue: [],
      moduleCascaderOptions: []
    }
  },
  created () {
    this.loadGetPageList()
  },
  methods: {
    async loadGetPageList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await queryPageList(queryObj)
      if (res.status === 200) {
        this.tableDataPage = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    async loadGetModuleAll () {
      const queryObj = { cascader_type: 'module' }
      const { data: res } = await queryCascader(queryObj)
      if (res.status === 200) {
        this.moduleCascaderOptions = res.data
      }
    },
    // 接口信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadGetPageList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadGetPageList()
      console.log(`当前页: ${val}`)
    },
    handleAdd () {
      console.log('新增页面')
      this.isAdd = true
      this.ruleFormPage = {}
      this.moduleCascaderValue = []
      this.dialogVisiblePage = true
      this.loadGetModuleAll()
    },
    handleEdit (rowData) {
      console.log('编辑页面')
      this.ruleFormPage = rowData
      this.isAdd = false
      this.loadGetModuleAll()
      this.moduleCascaderValue = [rowData.project_id, rowData.module_id]
      this.dialogVisiblePage = true
    },
    handleDel (rowData) {
      console.log('删除页面')
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const deleteObj = { page_id: rowData.page_id }
        const { data: res } = await deletePage(deleteObj)
        if (res.status === 200) {
          this.$message.success('删除用例成功')
          this.loadGetPageList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async processAddPage () {
      const addObj = this.ruleFormPage
      const { data: res } = await addPage(addObj)
      if (res.status === 200) {
        this.dialogVisiblePage = false
        this.loadGetPageList()
        this.$message.success('添加成功')
      }
    },
    async processEditPage () {
      const editObj = this.ruleFormPage
      const { data: res } = await editPage(editObj)
      if (res.status === 200) {
        this.dialogVisiblePage = false
        this.loadGetPageList()
        this.$message.success('编辑成功')
      }
    },
    closeDialogHandle () {
      this.$refs.ruleFormRefPage.resetFields()
      this.loadGetPageList()
    },
    moduleHandleChange () {
      this.ruleFormPage.project_id = this.moduleCascaderValue[0]
      this.ruleFormPage.module_id = this.moduleCascaderValue[1]
    }
  }
}
</script>

<style>

</style>
